import { Box, Divider, Typography } from '@partner-tech/pax-ui-kit';
import useTranslation from 'react-packages/hooks/useTranslation';
import { LegalEntityTinT } from 'react-packages/queries/useTinsQuery';
import { memo, ReactElement } from 'react';
import DataItemRow from 'react-packages/components/DataItemRow';
import { getCountryFromCountryCode } from 'react-packages/utils/country';
import { LegalEntityDetailsResponseT } from 'react-packages/apiSpecification/legalEntityDetails';

interface TaxIdentificationPropsT {
  isSoleProprietor?: boolean;
  tins: LegalEntityTinT[];
  legalEntityDetails: LegalEntityDetailsResponseT;
}

const TaxIdentification = ({
  isSoleProprietor,
  tins,
  legalEntityDetails,
}: TaxIdentificationPropsT): ReactElement => {
  const { t: tPage } = useTranslation('TAX_IDENTIFICATION');

  const countryOfResidenceTin = tins.find(
    (tin) => tin.countryCode === legalEntityDetails.address.countryCode
  )?.tin;

  const remainingResidenceTins = tins.filter(
    (tin) => tin.countryCode !== legalEntityDetails.address.countryCode
  );

  return (
    <Box pl={5} pb={4} pr={3}>
      <Typography variant="h3">{tPage('TITLE')}</Typography>

      <Box mt={3}>
        <DataItemRow
          label={tPage('LEGAL_TYPE')}
          value={tPage(
            isSoleProprietor
              ? 'SOLE_PROPRIETORSHIP_LEGAL_TYPE'
              : 'OTHER_LEGAL_TYPE'
          )}
        />
        <DataItemRow
          label={tPage('HEADQUARTER_COUNTRY')}
          value={getCountryFromCountryCode(
            legalEntityDetails.address.countryCode
          )}
        />
        <DataItemRow label={tPage('TAX_ID')} value={countryOfResidenceTin} />
      </Box>

      {remainingResidenceTins.map(({ countryCode, tin }) => {
        return (
          <Box key={countryCode}>
            <Box my={3}>
              <Divider light />
            </Box>
            <DataItemRow
              label={tPage('OTHER_FE_COUNTRY')}
              value={getCountryFromCountryCode(countryCode)}
            />
            <DataItemRow label={tPage('TAX_ID')} value={tin} />
          </Box>
        );
      })}
    </Box>
  );
};

export default memo(TaxIdentification);
