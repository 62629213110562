<template lang="pug">
div.root.mcp-admin-merchant-details-section(:class="{'is-loading':isLoading}")
  div.mcp-admin-merchant-details-section__header Company Contacts
    ConfirmDataChange(
      v-if="!readonly && !isEditMode"
      :confirmCb="onEditHandler"
      :customEditText="isMarketSpecific ? '' : 'Use Different Contact'"
    )
    span.mcp-admin-merchant-detail__header-label(v-if="showMarketSpecificLabel && isMarketSpecific")
      | Sales channel specific data
    span.mcp-admin-merchant-detail__header-label(v-if="showDataChangedLabel" data-testid="data-change-label")
      | Data for review

  //- Loading indicator
  div.mcp-admin__spinner-container(data-testid="loading-indicator" v-if="isLoading")
      div.dc-spinner

  ContactsForm(
    data-testid="contacts-form"
    v-else-if="isEditMode"
    :is-submitting="isSubmitting"
    :initial-contacts="contacts"
    :show-use-merchant-contacts-link="isMarketSpecific"
    @cancel="cancel"
    @save="save"
    @form-changed="formChanged = true"
    @useMerchantContacts="useMerchantContacts"
    is-market
  )

  div(v-else-if="contacts.length === 0" data-testid="no-contacts") No contacts data available

  div(v-else)
    div.contact-group(v-for="cGroup in groupedContacts" data-testid="cgroup")
      div.dc-h4.ctype-label(data-testid="cgroup-title") {{ typeLabels[cGroup.ctype] }}
      div
        div.contact(v-for="contact in cGroup.items" data-testid="contact")
          div.contact-field
            div.label First name
            div {{ contact.firstName }}
          div.contact-field
            div.label Last name
            div {{ contact.lastName }}
          div.contact-field
            div.label Email
            div {{ contact.email }}
          div.contact-field
            div.label Phone
            div {{ contact.phone || '-' }}
</template>

<script lang="ts">
import Vue from 'vue';
import {
  fetchContacts,
  updateContacts,
  useMerchantContacts,
} from 'Api/endpoints/sales-channel/contact.endpoint';
import ContactsForm from 'Merchant/general/components/company-details-contacts/company-details-contacts-form-container.vue';
import ConfirmDataChange from '@/common/components/confirm-data-change/confirm-data-change.vue';
import {
  CTYPE_LABELS,
  COUNTRY_CONTACT_TYPES,
  CTYPE_GENERAL,
} from 'Merchant/general/components/company-details-contacts/company-details-contacts.constant';
import { USER_DID_NOT_CONFIRM_ERROR } from 'Common/constants/misc.constant';
import { groupBy, prop } from 'ramda';
import { sendUpdateMerchantDataReason } from '@/api/endpoints/merchant/merchant.endpoint.es';

export default Vue.extend({
  components: {
    ContactsForm,
    ConfirmDataChange,
  },
  props: {
    merchantId: {
      type: String,
      required: true,
    },
    salesChannelId: {
      type: String,
      required: true,
    },
    fulfillmentType: {
      type: String,
      required: false,
      default: '',
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    showDataChangedLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    showMarketSpecificLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    getConfirm: {
      type: Function,
      required: false, // required for non-readonly version only
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      isEditMode: false,
      isSubmitting: false,
      contacts: [],
      typeLabels: CTYPE_LABELS,
      editReason: '',
      formChanged: false,
    };
  },
  computed: {
    isMarketSpecific(): boolean {
      return (
        !!this.contacts.length && this.contacts[0].source === 'SALES_CHANNEL'
      );
    },
    groupedContacts(): unknown[] {
      let contactsByType = groupBy(prop('contactType'), this.contacts);

      const mainContact = contactsByType[CTYPE_GENERAL];

      return COUNTRY_CONTACT_TYPES.map((ctype) => ({
        ctype,
        items: contactsByType[ctype] || mainContact || [],
      }));
    },
  },
  mounted() {
    fetchContacts(this.merchantId, this.salesChannelId).then((items) => {
      this.contacts = items;
      this.isLoading = false;
    });
  },
  methods: {
    onEditHandler(reason: string): void {
      this.editReason = reason;
      this.isEditMode = true;
    },
    cancel(): void {
      this.isEditMode = false;
      this.editReason = '';
      this.formChanged = false;
    },
    save(contacts): void {
      if (this.formChanged) {
        this.isSubmitting = true;
        updateContacts(this.merchantId, this.salesChannelId, contacts)
          .then((newContacts) => {
            this.contacts = newContacts;
            this.isEditMode = false;
            this.$emit('save', 'Company contacts successfully saved');
          })
          .catch((err) => {
            if (err !== USER_DID_NOT_CONFIRM_ERROR) {
              this.$emit('error', 'Unable to save Company contacts');
            }
          })
          .finally(() => {
            this.isSubmitting = false;
          });
        if (this.editReason) {
          sendUpdateMerchantDataReason(this.merchantId, this.editReason, {
            salesChannelId: this.salesChannelId,
            fulfilmentType: this.fulfillmentType,
          });
        }
      } else {
        this.cancel();
      }
    },
    useMerchantContacts(): void {
      this.isSubmitting = true;

      this.getConfirm()
        .then(() => useMerchantContacts(this.merchantId, this.salesChannelId))
        .then((newContacts) => {
          this.contacts = newContacts;
          this.isEditMode = false;
          this.$emit('save', 'Company contacts successfully saved');
        })
        .catch((err) => {
          if (err !== USER_DID_NOT_CONFIRM_ERROR) {
            this.$emit('error', 'Unable to save Company contacts');
          }
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
});
</script>

<style lang="scss" scoped>
.mcp-admin-merchant-details-section {
  border-bottom: 1px solid #d1d1d1;
}
.contact-group {
  margin-bottom: 18px;
}
.contact {
  margin-bottom: 10px;
}
.ctype-label {
  margin-top: 20px;
  margin-bottom: 10px;
}
.contact-field {
  display: flex;
}
.label {
  width: 145px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 11px;
}
.root.is-loading {
  min-height: 200px;
}
.mcp-admin-merchant-details-section__header ::v-deep div.v-application--wrap {
  margin-top: 0;
  min-height: auto;
}
</style>
