import appTranslations from 'react-packages/constants/app_en.json';
import { path } from 'ramda';
import { useMemo } from 'react';

export type AppTranslationFnT = (
  key: string,
  options?: { [key: string]: string | number }
) => string;

interface AppTranslationT {
  t: AppTranslationFnT;
}

const useTranslation = (nameSpace = ''): AppTranslationT => {
  return useMemo(() => {
    const nameSpaceObj = path(nameSpace.split('.'), appTranslations);
    return {
      t: (key, options) => {
        let text = path(key.split('.'), nameSpaceObj) as string;

        if (text) {
          if (options) {
            Object.keys(options).forEach((optionKey) => {
              const regex = new RegExp(`{{${optionKey}}}`, 'g');
              text = text.replace(regex, String(options[optionKey]));
            });
          }
          return text;
        } else {
          // eslint-disable-next-line no-console
          console.warn('Translation missing for:', `${nameSpace}.${text}`);
          return `${nameSpace}.${text}`;
        }
      },
    };
  }, [nameSpace]);
};

export default useTranslation;
