import { createContext, ReactElement, ReactNode } from 'react';
import {
  HistoryStateT,
  useCreateHistory,
} from 'react-packages/hooks/useHistory';

interface RouterDomContextValueT {
  params: { [key: string]: string };
  history: HistoryStateT;
}

export const RouterDomContext = createContext<RouterDomContextValueT>({
  params: {},
  history: {} as HistoryStateT,
});

interface RouterDomContextProviderPropsT {
  $stateParams: { [key: string]: string };
  children: ReactNode;
  windowObj?: Window & typeof globalThis;
}

export const RouterDomContextProvider = ({
  $stateParams,
  children,
  windowObj,
}: RouterDomContextProviderPropsT): ReactElement => {
  const history = useCreateHistory(windowObj);

  return (
    <RouterDomContext.Provider
      value={{
        params: $stateParams,
        history,
      }}
    >
      {children}
    </RouterDomContext.Provider>
  );
};
