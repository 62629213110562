import { AxiosResponse, ResponseType } from 'axios';

export interface FetchHeaderConfigT {
  Accept?: string;
  'Content-Type'?: string;
  'API-Version'?: string;
  'Cache-Control'?: string;
}

export interface ApiFetchConfigT {
  serviceUrl: ServiceUrlT;
  url: string;
  method?: ApiMethodT;
  body?: object | FormData;
  headers?: FetchHeaderConfigT;
  queryParams?: Record<string, string> | string | URLSearchParams;
  responseType?: ResponseType;
  isFormData?: boolean;
}

export type ApiResponseT<ResponseT> = Promise<AxiosResponse<ResponseT>>;

export enum ServiceUrlT {
  MERCHANT_PROFILE_SERVICE = '/api/merchant-profile',
}

export enum ApiMethodT {
  DELETE = 'DELETE',
  GET = 'GET',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
}

export interface ApiServicesT {
  merchantProfileApiFetch: <ResponseT = unknown>(
    config: Omit<ApiFetchConfigT, 'serviceUrl'>
  ) => ApiResponseT<ResponseT>;
}

export interface ErrorResponseT {
  response: AxiosResponse;
}
