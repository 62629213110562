<template lang="pug">
v-app.root(id="vue-contract-company-data")
  Contacts(
    :merchant-id="merchant.$id"
    :sales-channel-id="salesChannelId"
    :fulfillment-type="contract.fulfillmentType"
    :get-confirm="getConfirm"
    @save="onSaveSuccess"
    @error="showSaveError"
  )

  //- Comany Address (readonly)
  div(v-if="!isVatEnabled").mcp-admin-merchant-details-section
    div.mcp-admin-merchant-details-section__header Company Address

    AddressReadonly(
      v-if="contract.address || merchant.address"
      :address="contract.address || merchant.address"
    )

    div(v-else) No address data available

  ReturnAddress(
    v-if="isPartnerFulfilled"
    :merchant-id="merchant.$id"
    :sales-channel-id="salesChannelId"
    :fulfillment-type="contract.fulfillmentType"
    :country-name="countryName"
    @save="onSaveSuccess"
    @error="showSaveError"
  )

  //- Confirmation Modal window
  v-dialog(
    v-model="showConfirmModal"
    persistent
    max-width="390"
  )
    v-card
      v-card-title.headline Warning

      v-card-text
        | This modification will change status of the contract to "
        i in progress
        |". <br> Are you sure?

      v-card-actions
        v-spacer
        v-btn(text @click="reject") Cancel
        v-btn(color="primary" @click="confirm") Save

  //- Snackbar to notify about failed Save operation
  v-snackbar(
    v-model="isSaveErrorDisplayed"
    content-class="d-flex align-center justify-space-between"
    color="reject"
    bottom
    :timeout="-1"
  )
    | {{saveErrorMsg}}
    v-btn.ml-4.mr-n5(@click="isSaveErrorDisplayed = false" text) Close
</template>

<script lang="ts">
import Vue from 'vue';
import { MerchantProfile } from 'Api/models/MerchantProfile';
import { Contract } from 'Api/models/Contract';
import { MCP_CONTRACT_STATUSES as STATUSES } from 'Common/constants/contract-statuses.constant';
import { USER_DID_NOT_CONFIRM_ERROR } from 'Common/constants/misc.constant';
import { MCP_FF_TYPES } from 'Common/constants/misc.constant';
import Contacts from '../contract-contacts/contacts-container.vue';
import AddressReadonly from 'Merchant/contract/components/return-address/address-read-only.vue';
import ReturnAddress from 'Merchant/contract/components/return-address/return-address-container.vue';
import BankData from 'Merchant/contract/components/bank-data/bank-data.container.vue';
import BillingData from 'Merchant/contract/components/contract-billing-details/billing-details-container.vue';
import { canEnableVatCollection } from '@/feature-flags';

export default Vue.extend({
  components: {
    Contacts,
    AddressReadonly,
    ReturnAddress,
    BankData,
    BillingData,
  },

  props: {
    merchant: {
      type: Object as () => MerchantProfile,
      required: true,
    },
    contract: {
      type: Object as () => Contract,
      required: true,
    },
    onSaveSuccess: {
      type: Function,
      required: false, // required for non-readonly version only
      default: null,
    },
  },

  data() {
    return {
      showConfirmModal: false,
      isSaveErrorDisplayed: false,
      saveErrorMsg: '',
      resolveConfirmPromiseFn: null, // private variable
      rejectConfirmPromiseFn: null, // private variable
      isVatEnabled: canEnableVatCollection(this.merchant),
    };
  },

  computed: {
    isPartnerFulfilled(): boolean {
      return this.contract.fulfillmentType === MCP_FF_TYPES.PF;
    },

    salesChannelId(): string {
      return this.contract.salesChannelId;
    },

    companyName(): string {
      return this.merchant.companyName;
    },

    countryName(): string {
      return this.contract.salesChannel.countryName;
    },
  },

  methods: {
    confirm(): void {
      this.showConfirmModal = false;
      this.resolveConfirmPromiseFn();
      this.resolveConfirmPromiseFn = null;
      this.rejectConfirmPromiseFn = null;
    },

    reject(): void {
      this.showConfirmModal = false;
      this.rejectConfirmPromiseFn(USER_DID_NOT_CONFIRM_ERROR);
      this.resolveConfirmPromiseFn = null;
      this.rejectConfirmPromiseFn = null;
    },

    getConfirm(): Promise<unknown> {
      if (this.contract.status === STATUSES.IN_PROGRESS) {
        return Promise.resolve();
      }

      this.showConfirmModal = true;

      return new Promise((resolve, reject) => {
        this.resolveConfirmPromiseFn = resolve;
        this.rejectConfirmPromiseFn = reject;
      });
    },

    showSaveError(msg: string): void {
      this.isSaveErrorDisplayed = true;
      this.saveErrorMsg = msg;
    },
  },
});
</script>

<style lang="scss" scoped>
.root {
  background-color: white;
}
.root ::v-deep .v-application--wrap {
  min-height: auto;
  margin-top: -30px;
}
.root ::v-deep .dc-btn--link {
  color: #26aafe;
}
</style>
