<!-- eslint-disable max-len -->
<template lang="pug">
  div.root
    div.mt-8
      div(v-if="isLoading || isSelectedReturnServiceProvidersLoading") Loading...
      div(v-else)
        h4.pb-2 Optional outbound & return solutions
        p
          | Before configuring any optional solutions for partners using self fufilment,
          | ensure that relevant contracts are signed and partners have signed off on changes.
          span(v-if="isHybridChannel")
            |  Note that <strong>ZRS Plus & ZRS Pure can not be enabled at the same time.</strong>

        v-switch(
          v-model="isZSS"
          label="Zalando Shipping Solution (ZSS)"
        )

        v-flex(
          d-flex
          style="flex-direction: row;"
        )
          v-switch(
            v-model="isZRSPure"
            :disabled="isZRSPlus"
            label="Zalando Return Solutions (ZRS) Pure"
          )
          v-flex(
            v-if="!isZRSPure && zrsPureOffboardedAt && zrsPureOffboardedAt !== new Date()"
            d-flex
            style="align-items: center;"
          )
            v-chip.left-margin(
              color="#FCD999",
              text-color="#5C3A1E",
              small
            )
              span.font-weight-bold
                | GRACE PERIOD
            span.mcp-admin__field-info.dc--has-tooltip.dc--has-tooltip--top-right.side-margin(
              data-dc-has-tooltip="Partner has requested for offboarding and is in the process of getting deactivated from Zalando Returns Solutions (ZRS). During this grace period, the returns will continue to be handled by ZRS."
            )
              i.dc-icon.dc-icon--info
            span
              | (Period end date: {{getDateOnly(zrsPureOffboardedAt)}})

        v-flex(
          v-if="isHybridChannel"
          d-flex
          style="flex-direction: row;"
        )
          v-switch(
            v-model="isZRSPlus"
            :disabled="!isZfsChannelLaunched || (isZfsChannelLaunched && isZRSPure)"
            label="Zalando Return Solutions (ZRS) Plus"
          )
          span.mcp-admin__field-info.dc--has-tooltip.dc--has-tooltip--top-right.side-margin.top-margin(
            v-if="!isZfsChannelLaunched"
            data-dc-has-tooltip="ZRS Plus will be enabled once the ZFS contract is LAUNCHED."
          )
            i.dc-icon.dc-icon--info
          v-flex(
            v-if="!isZRSPlus && zrsPlusOffboardedAt && zrsPlusOffboardedAt !== new Date()"
            d-flex
            style="align-items: center;"
          )
            v-chip.left-margin(
              color="#FCD999",
              text-color="#5C3A1E",
              small
            )
              span.font-weight-bold
                | GRACE PERIOD
            span.mcp-admin__field-info.dc--has-tooltip.dc--has-tooltip--top-right.side-margin(
              data-dc-has-tooltip="Partner has requested for offboarding and is in the process of getting deactivated from Zalando Returns Solutions (ZRS). During this grace period, the returns will continue to be handled by ZRS."
            )
              i.dc-icon.dc-icon--info
            span
              | (Period end date: {{getDateOnly(zrsPlusOffboardedAt)}})
        //- Remove the v-if condition during flag clean up
        v-flex(v-if="isFeatureRlgChangesEnabled" d-flex style="justify-content: flex-end;")
            v-btn.mr-2(
              :text="isInputDirty"
              color="grey"
              @click="resetState"
              elevation="2"
              :disabled="!isInputDirty"
            ) Cancel
            v-btn(
              data-testid="save-cta"
              color="primary"
              @click="showModal = true"
              :disabled="!isInputDirty"
            ) Save

        v-flex.mt-6(
          d-flex
          style="flex-direction: column;"
        )
          h4.pb-2 Return Label Generation
          p
            | Before configuring return label generation for partners using self fufilment,
            | ensure that the partner is eligible for RLG.
          v-switch(
            v-model="isRlgEnabled"
            label="Return Label Generation (RLG)"
            :inset="isFeatureRlgChangesEnabled"
            :disabled="!isRLGEligible"
            :messages="returnServiceProvider"
            @change="whenRlgIsChanged"
          )
          div.mt-5(v-if="showReasonForDeactivationBox")
            v-textarea(
            filled
            autofocus
            label="Add reason for deactivation"
            v-model="inputReasonForDeactivation"
          )
          div.mt-3(v-if="!isRlgEnabled && reasonForDeactivation")
            p
              | Reason for deactivation: {{reasonForDeactivation}}

        v-flex(v-if="isFeatureRlgChangesEnabled" d-flex style="justify-content: flex-end;")
            v-btn.mr-2(
              data-testid="cancel-rlg-cta"
              :text="isRlgInputDirty"
              color="grey"
              @click="resetState"
              elevation="2"
              :disabled="!isRlgInputDirty"
            ) Cancel
            v-btn(
              data-testid="save-rlg-cta"
              color="primary"
              @click="onRlgSaveClicked"
              :disabled="!isRlgInputDirty"
            ) Save
        //- Remove below code during flag clean up
        v-flex(v-if="!isFeatureRlgChangesEnabled" d-flex style="justify-content: flex-end;")
            v-btn.mr-2(
              :text="isInputDirty"
              color="grey"
              @click="resetState"
              elevation="2"
              :disabled="!isInputDirty"
            ) Cancel
            v-btn(
              data-testid="save-cta"
              color="primary"
              @click="showModal = true"
              :disabled="!isInputDirty"
            ) Save

    v-dialog(v-model="showModal" width="550")
      v-card
        mpAlert.text-h6(type="warning") Solutions configuration

        v-card-text.pa-2
          p.p-4
            | You are about to modify the outbound or returns solutions configuration of a partner.
            | This change will be updated automatically by all other systems.
            | Please ensure that the partner has signed off on the configuration.

          v-card-actions
            v-spacer
            v-btn(text @click="showModal = false") Cancel

            v-btn(
              data-testid="confirm-modal-save"
              color="primary"
              depressed
              @click="updateShipmentFlags"
              :loading="isShipmentLoading"
            ) Save

    v-dialog(v-if="isFeatureRlgChangesEnabled" v-model="showRlgChangesModal" width="550")
      v-card
        mpAlert.text-h6(type="warning") Deactivate label generation in {{countryName}} for Partner {{merchantName}}?

        v-card-text.pa-2
          p.p-4
            | Return labels will not be generated for customer returns in this country for this partner.

          v-card-actions
            v-spacer
            v-btn(text @click="showRlgChangesModal = false") Cancel

            v-btn(
              data-testid="confirm-rlg-changes-modal-save"
              color="primary"
              depressed
              @click="updateShipmentFlags(true)"
              :loading="isShipmentLoading"
            ) Deactivate

</template>

<script lang="ts">
import Vue from 'vue';
import { ShipmentServiceT } from 'Api/models/ShipmentService';
import Swal from 'sweetalert2';
import {
  RlgStatusT,
  SelectedReturnServiceProviderT,
} from '@/api/models/SelectedReturnServiceProviders';
import { canEnableRlgChanges } from '@/feature-flags';

export default Vue.extend({
  props: {
    merchantName: { type: String, required: true },
    countryName: { type: String, required: true },
    shipmentData: {
      type: Object as () => ShipmentServiceT,
      required: false,
      default: null,
    },
    onShipmentChange: { type: Function, required: true },
    isZfsChannel: { type: Boolean, required: true },
    isHybridChannel: { type: Boolean, required: true },
    isZfsChannelLaunched: { type: Boolean, required: true },
    isLoading: { type: Boolean, required: true },
    selectedReturnServiceProvider: {
      type: Object as () => SelectedReturnServiceProviderT,
      required: false,
      default: null,
    },
    isSelectedReturnServiceProvidersLoading: { type: Boolean, required: true },
  },

  data() {
    return {
      isShipmentLoading: false,
      showModal: false,
      showRlgChangesModal: false,
      isZSS: null,
      isZRSPure: null,
      isZRSPlus: null,
      isRLGEligible: null,
      isRlgEnabled: null,
      zrsPureDisabledAt: null,
      zrsPureOffboardedAt: null,
      zrsPlusDisabledAt: null,
      zrsPlusOffboardedAt: null,
      isFeatureRlgChangesEnabled: canEnableRlgChanges(),
      returnServiceProvider: null,
      reasonForDeactivation: null,
      rlgStatus: null,
      inputReasonForDeactivation: '',
      showReasonForDeactivationBox: false,
    };
  },

  computed: {
    hasZSSChanged() {
      if (!this.shipmentData) return false;

      return this.isZSS !== this.shipmentData.outbound.zssEnabled;
    },
    hasZRSPureChanged() {
      if (!this.shipmentData) return false;

      return this.isZRSPure !== this.shipmentData.inbound.zrsPure?.enabled;
    },
    hasZRSPlusChanged() {
      if (!this.shipmentData) return false;

      return this.isZRSPlus !== this.shipmentData.inbound.zrsPlus?.enabled;
    },
    hasRLGChanged() {
      if (!this.isFeatureRlgChangesEnabled) {
        return this.isRlgEnabled !== this.shipmentData.inbound.rlgEnabled;
      } else {
        if (this.rlgStatus === RlgStatusT.DISABLED) {
          return this.inputReasonForDeactivation !== '';
        } else {
          return this.rlgStatus !== this.shipmentData.inbound.rlg.status;
        }
      }
    },
    hasOnlyRlgInboundChanges() {
      return (
        !this.hasZRSPureChanged && !this.hasZRSPlusChanged && this.hasRLGChanged
      );
    },
    hasInboundChanged() {
      return (
        this.hasZRSPureChanged ||
        this.hasZRSPlusChanged ||
        (!this.isFeatureRlgChangesEnabled && this.hasRLGChanged)
      );
    },
    hasOutboundChanged() {
      return this.hasZSSChanged;
    },
    isInputDirty() {
      if (!this.shipmentData) return false;

      return this.hasInboundChanged || this.hasOutboundChanged;
    },
    isRlgInputDirty() {
      if (!this.shipmentData) return false;

      return this.hasRLGChanged;
    },
  },

  watch: {
    shipmentData(newShipmentData) {
      this.isZSS = newShipmentData.outbound.zssEnabled;
      this.isZRSPure = newShipmentData.inbound.zrsPure?.enabled;
      this.zrsPureDisabledAt = newShipmentData.inbound.zrsPure?.disabledAt;
      this.zrsPureOffboardedAt =
        newShipmentData.inbound.zrsPure?.fullyOffboardedAt;
      this.isZRSPlus = newShipmentData.inbound.zrsPlus?.enabled;
      this.zrsPlusDisabledAt = newShipmentData.inbound.zrsPlus?.disabledAt;
      this.zrsPlusOffboardedAt =
        newShipmentData.inbound.zrsPlus?.fullyOffboardedAt;
      this.isRLGEligible = this.isFeatureRlgChangesEnabled
        ? newShipmentData.inbound.rlg?.eligible &&
          newShipmentData.inbound.rlg?.status &&
          newShipmentData.inbound.rlg?.status !== RlgStatusT.INACTIVE
        : newShipmentData.inbound.rlgEligible;
      this.isRlgEnabled = this.isFeatureRlgChangesEnabled
        ? this.selectedReturnServiceProvider
          ? newShipmentData.inbound.rlg?.status === RlgStatusT.ACTIVE
          : newShipmentData.inbound.rlg?.status == RlgStatusT.INACTIVE
        : newShipmentData.inbound.rlgEnabled;
      this.rlgStatus = newShipmentData.inbound.rlg?.status;
      this.returnServiceProvider =
        this.selectedReturnServiceProvider !== null
          ? `Return service provider: ${this.selectedReturnServiceProvider?.name}`
          : '';
      this.reasonForDeactivation = this.isFeatureRlgChangesEnabled
        ? newShipmentData.inbound.rlg?.reason
        : '';
    },
  },

  mounted() {
    this.resetState();
  },

  // TODO: Allow toggling when the RLG status is INACTIVE??

  methods: {
    resetState() {
      this.isZSS = this.shipmentData.outbound.zssEnabled;
      this.isZRSPure = this.shipmentData.inbound.zrsPure?.enabled;
      this.zrsPureDisabledAt = this.shipmentData.inbound.zrsPure?.disabledAt;
      this.zrsPureOffboardedAt =
        this.shipmentData.inbound.zrsPure?.fullyOffboardedAt;
      this.isZRSPlus = this.shipmentData.inbound.zrsPlus?.enabled;
      this.zrsPlusDisabledAt = this.shipmentData.inbound.zrsPlus?.disabledAt;
      this.zrsPlusOffboardedAt =
        this.shipmentData.inbound.zrsPlus?.fullyOffboardedAt;
      this.isRLGEligible = this.isFeatureRlgChangesEnabled
        ? this.shipmentData.inbound.rlg.eligible &&
          this.shipmentData.inbound.rlg.status &&
          this.shipmentData.inbound.rlg?.status !== RlgStatusT.INACTIVE
        : this.shipmentData.inbound.rlgEligible;
      this.isRlgEnabled = this.isFeatureRlgChangesEnabled
        ? this.selectedReturnServiceProvider
          ? this.shipmentData.inbound.rlg.status === RlgStatusT.ACTIVE
          : this.shipmentData.inbound.rlg.status == RlgStatusT.INACTIVE
        : this.shipmentData.inbound.rlgEnabled;
      this.rlgStatus = this.shipmentData.inbound.rlg.status;
      this.reasonForDeactivation = this.isFeatureRlgChangesEnabled
        ? this.shipmentData.inbound.rlg.reason
        : '';
      this.inputReasonForDeactivation = '';
      this.showReasonForDeactivationBox = false;
    },
    onRlgSaveClicked() {
      if (this.rlgStatus === RlgStatusT.DISABLED) {
        this.showRlgChangesModal = true;
      } else {
        this.updateShipmentFlags(true);
      }
    },
    async updateShipmentFlags(updateRlgOnly = false) {
      this.isShipmentLoading = true;

      try {
        let theObjToSend;
        if (this.isFeatureRlgChangesEnabled && updateRlgOnly) {
          theObjToSend = {
            inbound: {
              rlg: {
                status: this.rlgStatus,
                reason: this.inputReasonForDeactivation,
              },
            },
          };
        } else {
          theObjToSend = {
            ...(this.hasOutboundChanged && {
              outbound: {
                zssEnabled: this.isZSS,
              },
            }),
            ...(this.hasInboundChanged && {
              inbound: {
                ...(!this.isFeatureRlgChangesEnabled &&
                  this.hasRLGChanged && {
                    rlgEnabled: this.isRlgEnabled,
                  }),
                ...(this.hasZRSPureChanged && {
                  zrsPure: {
                    enabled: this.isZRSPure,
                  },
                }),
                ...(this.hasZRSPlusChanged && {
                  zrsPlus: {
                    enabled: this.isZRSPlus,
                  },
                }),
              },
            }),
          };
        }

        await this.onShipmentChange(theObjToSend);
        this.showModal = false;
        this.showRlgChangesModal = false;
        Swal.fire({
          html: 'Zalando outbound/return solutions updated successfully!',
          timer: 2000,
          position: 'top-end',
          showConfirmButton: false,
          toast: true,
          icon: 'success',
        });
      } catch (e) {
        Swal.fire({
          html: 'Something went wrong while updating Zalando outbound/return solutions. Please try again',
          timer: 2000,
          position: 'top-end',
          showConfirmButton: false,
          toast: true,
          icon: 'error',
        });
      }
      this.isShipmentLoading = false;
    },
    getDateOnly(date) {
      return new Date(date.substr(0, date.indexOf('T'))).toLocaleDateString();
    },
    whenRlgIsChanged(value) {
      if (this.isFeatureRlgChangesEnabled) {
        if (!value) {
          this.rlgStatus = RlgStatusT.DISABLED;
          if (!this.reasonForDeactivation) {
            this.showReasonForDeactivationBox = true;
          }
        } else {
          this.showReasonForDeactivationBox = false;
          this.rlgStatus = this.selectedReturnServiceProvider
            ? RlgStatusT.ACTIVE
            : RlgStatusT.INACTIVE;
        }
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.left-margin {
  margin-left: 12px;
}
.top-margin {
  margin-top: 24px;
}
.side-margin {
  margin-left: 6px;
  margin-right: 6px;
}
.dc--has-tooltip:after {
  white-space: normal;
  width: 320px;
  text-align: justify;
  font-size: 12px;
  line-height: 16px;
}
</style>
