import template from './sidebar-contract-menu-item.html';
import { ZALANDO_FASHION_STORE_RETAILERID } from 'Common/constants/retailer.constant';
import { SalesChannelExt } from 'Api/models/SalesChannel';
import { Contract } from 'Api/models/Contract';
import { canEnableVatCollection } from '@/feature-flags';
import { SalesChannelComplianceRequirementT } from 'Api/models/ComplianceRequirement';
import {
  fetchSalesChannelComplianceDetailsRequirements,
  fetchSalesChannelComplianceStatus,
} from 'Api/endpoints/sales-channel/compliance.endpoint';
import { MerchantProfile } from 'Api/models/MerchantProfile';
import { ApprovalStatusT } from 'Api/models/ApprovalStatus';

export class SidebarContractMenuItemComponent implements ng.IComponentOptions {
  static Factory() {
    return new SidebarContractMenuItemComponent();
  }

  bindings: { [binding: string]: string } = {
    contract: '<',
    channel: '<',
    status: '<',
    imagesPath: '<',
    merchant: '<',
  };

  controller = SidebarContractMenuItemController;
  controllerAs = 'ctrl';
  template: string = template;
}

export class SidebarContractMenuItemController
  implements angular.IComponentController
{
  static $inject = ['$stateParams'];

  // Injected by bindings
  channel: SalesChannelExt;
  contract: Contract;
  status: string;
  imagesPath: string;
  isVatEnabled: boolean;
  isComplianceReviewRequired: boolean;
  isComplianceStatusLoading: boolean;
  complianceStatus: ApprovalStatusT;
  merchant: MerchantProfile;

  constructor(private params) {}

  async $onInit() {
    // Initialisation
    this.isVatEnabled = canEnableVatCollection(this.merchant);
    if (this.isVatEnabled) {
      this.isComplianceStatusLoading = true;
      await this.shouldShowComplianceTab();
      await this.getComplianceStatus();
      this.isComplianceStatusLoading = false;
    }
  }

  isActive() {
    return (
      (this.channel && this.channel === this.params.salesChannelId) ||
      (this.contract && this.contract.$id === this.params.contractId)
    );
  }

  isSalesChannelNameNeeded() {
    return (
      (this.channel?.retailerId || this.contract?.retailerId) !==
      ZALANDO_FASHION_STORE_RETAILERID
    );
  }

  async shouldShowComplianceTab() {
    if (this.channel) {
      this.isComplianceReviewRequired =
        await fetchSalesChannelComplianceDetailsRequirements(
          this.params.merchantId,
          this.channel.$id
        ).then((list) =>
          list.includes(SalesChannelComplianceRequirementT.VAT_ID)
        );
    }
  }

  async getComplianceStatus() {
    if (this.channel && this.isComplianceReviewRequired) {
      const response = await fetchSalesChannelComplianceStatus(
        this.params.merchantId,
        this.channel.$id
      );

      this.complianceStatus = response?.status;
    }
  }

  isOnline(): boolean {
    return this.channel.contracts.some((contract) => contract.live);
  }
}
