import { useContext } from 'react';
import { RouterDomContext } from 'react-packages/context/RouterDomContext';

export interface HistoryStateT {
  push: (path: string) => void;
  replace: (path: string) => void;
  goBack: () => string;
  location: Location;
}

const INDEX_PATH = '/';

export const useCreateHistory = ({
  location,
  history,
} = window): HistoryStateT => {
  const push = (path: string) => {
    history.pushState({}, '', path);
  };

  const replace = (path: string) => {
    history.replaceState({}, '', path);
  };

  const goBack = () => {
    if (history.length > 1) {
      const newPath = history[history.length - 2];
      history.back();
      return newPath;
    }
    return INDEX_PATH;
  };

  return {
    push,
    replace,
    goBack,
    location,
  };
};

export const useHistory = (): HistoryStateT => {
  return useContext(RouterDomContext).history;
};
