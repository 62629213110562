import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "grey lighten-5 py-5" },
    [
      _c(
        VRow,
        _vm._l(_vm.list, function (carrierInfo) {
          return _c(
            VCol,
            { key: carrierInfo.id, attrs: { cols: "12" } },
            [
              _c("DeliveryMethodCard", {
                attrs: {
                  details: carrierInfo,
                  "on-delete": _vm.onDelete,
                  "disable-delete": _vm.list.length === 1,
                  "invalid-selection":
                    !_vm.isCarrierInvisible &&
                    !_vm.getDeliveryProduct(carrierInfo),
                },
              }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }