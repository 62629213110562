import { VAlert } from 'vuetify/lib/components/VAlert';
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VApp,
    { staticClass: "root", attrs: { id: "vue-contract-approval" } },
    [
      _vm.needGeneralDataApproval
        ? _c(VAlert, { attrs: { type: "info", text: "" } }, [
            _vm._v(
              "General Company Data should be approved first before this contract can be approved."
            ),
          ])
        : _vm._e(),
      _vm.isApproved
        ? _c(VAlert, { attrs: { type: "success", text: "" } }, [
            _vm._v("Approved"),
          ])
        : _vm._e(),
      _vm.isRejected
        ? _c(VAlert, { attrs: { type: "error", text: "" } }, [
            _vm._v("Rejected"),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "mcp-admin-merchant-details-section",
          staticStyle: { margin: "0" },
        },
        [
          _c(
            "div",
            { staticClass: "mcp-admin-merchant-details-section__header" },
            [_vm._v("Details")]
          ),
          _c("div", { staticClass: "mcp-admin-contract-overview__details" }, [
            _c(
              "div",
              { staticClass: "mcp-admin-contract-overview__details__item" },
              [
                _c(
                  "div",
                  {
                    staticClass: "mcp-admin-contract-overview__details__label",
                  },
                  [_vm._v("Business Partner Id")]
                ),
                _c("div", [_vm._v(_vm._s(_vm.merchant.$id))]),
              ]
            ),
            _c(
              "div",
              { staticClass: "mcp-admin-contract-overview__details__item" },
              [
                _c(
                  "div",
                  {
                    staticClass: "mcp-admin-contract-overview__details__label",
                  },
                  [_vm._v("Sales Channel Id")]
                ),
                _c("div", [_vm._v(_vm._s(_vm.contract.salesChannel.$id))]),
              ]
            ),
          ]),
        ]
      ),
      _c("Contacts", {
        attrs: {
          readonly: "",
          "show-market-specific-label": "",
          "merchant-id": _vm.merchantId,
          "sales-channel-id": _vm.salesChannelId,
          "show-data-changed-label": _vm.isInReview && _vm.isContactDataChanged,
        },
      }),
      !_vm.isVatEnabled
        ? _c(
            "div",
            { staticClass: "mcp-admin-merchant-details-section" },
            [
              _c(
                "div",
                { staticClass: "mcp-admin-merchant-details-section__header" },
                [
                  _vm._v("Company Address"),
                  _vm.address && _vm.address.source === "SALES_CHANNEL"
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "mcp-admin-merchant-detail__header-label",
                        },
                        [_vm._v("Sales channel specific data")]
                      )
                    : _vm._e(),
                  _vm.isInReview && _vm.isAddressChanged
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "mcp-admin-merchant-detail__header-label",
                        },
                        [_vm._v("Data for review")]
                      )
                    : _vm._e(),
                ]
              ),
              _vm.isAddressLoading
                ? _c("div", [_vm._v("Loading...")])
                : !_vm.address
                ? _c("div", [_vm._v("No address data available")])
                : _c("AddressReadonly", { attrs: { address: _vm.address } }),
            ],
            1
          )
        : _vm._e(),
      _c(
        VSnackbar,
        {
          attrs: {
            "content-class": "d-flex align-center justify-space-between",
            color: "reject",
            bottom: "",
            timeout: -1,
          },
          model: {
            value: _vm.isErrorDisplayed,
            callback: function ($$v) {
              _vm.isErrorDisplayed = $$v
            },
            expression: "isErrorDisplayed",
          },
        },
        [
          _vm._v(_vm._s(_vm.errorMsg)),
          _c(
            VBtn,
            {
              staticClass: "ml-4 mr-n5",
              attrs: { text: "" },
              on: {
                click: function ($event) {
                  _vm.isErrorDisplayed = false
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }