import { Box, Grid, Typography } from '@partner-tech/pax-ui-kit';
import { ReactElement } from 'react';

export interface DataItemRowPropsT {
  prefix?: ReactElement | null;
  suffix?: ReactElement | null;
  label: string;
  value?: string | ReactElement;
}

const DataItemRow = ({
  prefix,
  suffix,
  label,
  value = '',
}: DataItemRowPropsT): ReactElement => {
  return (
    <Box mb={2}>
      {prefix}
      <Grid container justifyContent="flex-left" alignItems="center">
        <Grid xs={3}>
          <Box textTransform="uppercase">
            <Typography variant="caption1">{label}</Typography>
          </Box>
        </Grid>
        <Grid xs={9}>
          <Typography variant="body2">{value}</Typography>
        </Grid>
      </Grid>
      {suffix}
    </Box>
  );
};

export default DataItemRow;
