import { IComponentOptions } from 'angular';
import { ComponentType, ReactElement } from 'react';
import { PaxThemeProvider } from '@partner-tech/pax-ui-kit/styles';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ErrorBoundary from 'react-packages/wrapper/ErrorBoundary';
import { SnackbarContextProvider } from 'react-packages/context/SnackbarContext';
import { RouterDomContextProvider } from 'react-packages/context/RouterDomContext';
import reactQueryClient from './queryClient';
import { reactToAngular } from './reactToAngular';

type InjectedParamsT = {
  $stateParams: Record<string, string>;
};

const angularInjectNames = ['$stateParams'];

const css = `
  body {
    font-weight: 300;
    font-family: 'Ubuntu', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    color: #4a4a4a;
  }
`;

const wrapProviders = <ComponentPropsT,>(
  Comp: ComponentType
): ComponentType<ComponentPropsT> => {
  const ReactRootComponent = ({
    $stateParams,
    ...restProps
  }: InjectedParamsT & ComponentPropsT): ReactElement => {
    return (
      <PaxThemeProvider>
        <style>{css}</style>
        <RouterDomContextProvider $stateParams={$stateParams}>
          <QueryClientProvider client={reactQueryClient}>
            <ErrorBoundary>
              <SnackbarContextProvider>
                <Comp {...restProps} />
              </SnackbarContextProvider>
            </ErrorBoundary>
            <ReactQueryDevtools buttonPosition="bottom-right" />
          </QueryClientProvider>
        </RouterDomContextProvider>
      </PaxThemeProvider>
    );
  };

  return ReactRootComponent;
};

const wrapReact = <ComponentPropsT extends { [k: string]: any }>(
  component: ComponentType,
  bindingNames?: (keyof ComponentPropsT)[]
): IComponentOptions => {
  return reactToAngular<ComponentPropsT>(
    wrapProviders<ComponentPropsT>(component),
    bindingNames,
    angularInjectNames
  );
};

export default wrapReact;
