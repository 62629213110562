import { mcb } from 'mcb';
import template from './merchant.html';
import { Store, Unsubscribe } from 'redux';
import { MCP_MERCHANT_STATUSES } from 'Common/constants/merchant-statuses.constant';
import { MerchantProfile } from 'Api/models/MerchantProfile';
import { MerchantRequirements } from 'Api/models/MerchantRequiremenets';
import { Contract } from 'Api/models/Contract';
import { RetailerToContracts } from 'Api/endpoints/contract/retailer.endpoint';
import { FlowStateSelector } from 'Merchant/common/state/selectors/flow-state.selector';
import { MerchantActions } from 'Merchant/common/state/actions/merchant.actions';
import { MerchantFlowActions } from 'Merchant/common/state/actions/merchant-flow.actions';
import { FlowState } from 'Merchant/common/state/flow.state';
import { DialogService } from 'Common/services/dialog.service';
import { MCP_CONTRACT_STATUSES } from 'Common/constants/contract-statuses.constant';
import { ContractActions } from 'Merchant/common/state/actions/contract.actions';
import { RetailerService } from 'Merchant/common/services/retailer.service';
import { USER_DID_NOT_CONFIRM_ERROR } from 'Common/constants/misc.constant';
import { hasFinancialApprovalPermissions } from 'Common/services/permissions.service';
import {
  isMerchantZPO,
  sendReviewReason,
} from '@/api/endpoints/merchant/merchant.endpoint.es';
import { canEnableVatCollection } from '@/feature-flags';
import { sendSalesChannelsForFinancialApproval } from 'Api/endpoints/contract/contract-new.endpoint';
import { fetchMerchantComplianceStatus } from 'Api/endpoints/merchant/merchant-compliance.endpoint';
import { fetchPartnerLegalEntityDetails } from 'Api/endpoints/legal-entity/legal-entity.endpoint';
import { ApprovalStatusT } from 'Api/models/ApprovalStatus';
import { sendMerchantForFinancialReview } from '@/api/endpoints/review-endpoints/merchant-review.endpoint';

export class MerchantContainer implements ng.IComponentOptions {
  static Factory() {
    return new MerchantContainer();
  }

  controller = MerchantContainerController;
  controllerAs = 'merchantCtrl';
  template: string = template;
}

export class MerchantContainerController {
  static $inject = [
    'mcbToast',
    'mcpAdminFlowStateSelector',
    'mcpAdminMerchantFlowActions',
    'mcpAdminMerchantActions',
    'mcpAdminContractActions',
    'mcpAdminRetailerService',
    '$ngRedux',
    'mcpAdminDialogService',
    'mcbConfig',
    '$state',
    '$stateParams',
    '$scope',
  ];

  merchantState;
  merchant: MerchantProfile;
  contract: Contract;
  merchantStatus: MerchantRequirements;
  merchantLoading: boolean;
  contractsLoading: boolean;
  contracts: Contract[];
  retailersToContracts: RetailerToContracts[];
  unsubscribe: Unsubscribe;
  isFinancialApprover: boolean;
  isZPOMerchant = true;
  isVatEnabled: boolean;
  companyComplianceStatus: ApprovalStatusT;
  partnerApprovalStatus: ApprovalStatusT;
  constructor(
    private toast: mcb.IToast,
    private flowSelector: FlowStateSelector,
    private merchantFlowActions: MerchantFlowActions,
    private merchantActions: MerchantActions,
    private contractActions: ContractActions,
    private retailerService: RetailerService,
    private store: Store<FlowState>,
    private dialogService: DialogService,
    private mcbConfig: mcb.config.IConfigProvider,
    private state: ng.ui.IStateService,
    private params,
    private $scope: ng.IScope
  ) {
    this.onGoOnlineCb = this.onGoOnlineCb.bind(this);
    this.onGoOnlineErrorCb = this.onGoOnlineErrorCb.bind(this);
    this.onGoOfflineCb = this.onGoOfflineCb.bind(this);
    this.onGoOfflineErrorCb = this.onGoOfflineErrorCb.bind(this);
    this.onApprove = this.onApprove.bind(this);
    this.onReject = this.onReject.bind(this);
    this.getComplianceDetailsStatus =
      this.getComplianceDetailsStatus.bind(this);
    this.getLegalEntityDetails = this.getLegalEntityDetails.bind(this);
  }

  $onInit(): void {
    this.listenStore();
    // @ts-ignore
    this.store.dispatch(this.merchantFlowActions.hydrateState());
    this.store
      .dispatch(
        // @ts-ignore
        this.merchantFlowActions.setCurrentMerchant(this.params.merchantId)
      )
      .then((merchant: MerchantProfile) => {
        this.isVatEnabled = canEnableVatCollection(this.merchant);
        // @ts-ignore
        this.store.dispatch(this.merchantFlowActions.setFlowStatus());
        this.store.dispatch(
          // @ts-ignore
          this.merchantFlowActions.setCurrentContracts(merchant)
        );
        this.store.dispatch(
          // @ts-ignore
          this.merchantFlowActions.setMerchantFactoringMigrationStatus(
            merchant.$id
          )
        );
        if (this.isVatEnabled) {
          this.getLegalEntityDetails(merchant.legalEntityId);
          this.getComplianceDetailsStatus();
        }
      })
      .catch(() => {
        this.toast.error("Can't find the merchant");
        this.state.go('mcp.admin.merchants');
      });

    this.$scope.$on('MasterdataExport', this.onMasterdataExport.bind(this));
    this.isFinancialApprover = hasFinancialApprovalPermissions();
  }

  $onDestroy(): void {
    this.unsubscribe();
  }

  onGoOnlineCb(countryName: string): void {
    const msg = `Sales channel ${countryName} successfully taken online`;
    this.reloadContracts(msg);
  }

  onGoOnlineErrorCb(countryName: string): void {
    const msg = `Something went wrong. Sales channel ${countryName} couldn't taken online`;
    this.reloadContracts(msg, true);
  }

  onGoOfflineCb(countryName: string): void {
    const msg = `Sales channel ${countryName} successfully taken offline`;
    this.reloadContracts(msg);
  }

  onGoOfflineErrorCb(countryName: string): void {
    const msg = `Something went wrong. Sales channel ${countryName} couldn't taken offline`;
    this.reloadContracts(msg, true);
  }

  private reloadContracts(msg: string, isError = false): void {
    this.store
      // @ts-ignore
      .dispatch(this.merchantFlowActions.setCurrentContracts())
      .then(() => (isError ? this.toast.error(msg) : this.toast.success(msg)));
  }

  canSendToFinancialReview(): boolean {
    return (
      this.merchantStatus &&
      this.merchantStatus.readyForFinancialReview &&
      this.merchant.status === MCP_MERCHANT_STATUSES.IN_PROGRESS
    );
  }

  isAnyContractInProgress(): boolean {
    return (
      this.contracts &&
      this.contracts.reduce((status: boolean, contract: Contract) => {
        return status || contract.status === MCP_CONTRACT_STATUSES.IN_PROGRESS;
      }, false)
    );
  }

  getContractsInProgress(): Contract[] {
    return this.contracts.reduce((contracts, contract: Contract) => {
      if (contract.status === MCP_CONTRACT_STATUSES.IN_PROGRESS) {
        contracts.push(contract);
      }

      return contracts;
    }, []);
  }

  getSalesChannelsForReview() {
    return this.getContractsInProgress().reduce((channels, contract) => {
      if (!channels[contract.salesChannelId]) {
        channels[contract.salesChannelId] = true;
      }

      return channels;
    }, {});
  }

  getContractsForReview(): any {
    let contractForReview = {};
    this.getContractsInProgress().forEach((contract) => {
      contractForReview[contract.$id] = true;
    });
    return contractForReview;
  }

  async sendToReview(): Promise<void> {
    const { toast } = this;

    try {
      const mapping = await this.retailerService.mapRetailersToContracts(
        this.getContractsInProgress()
      );
      const { reviewSelection, reason } =
        await this.dialogService.openReviewConfirmationDialog({
          isZPO: this.isZPOMerchant,
          merchantName: this.merchant.name,
          hasMerchantChanged:
            this.merchant.status === MCP_MERCHANT_STATUSES.IN_PROGRESS,
          retailersToContracts: mapping,
          reviewSelection: {
            merchant:
              this.merchant.status === MCP_MERCHANT_STATUSES.IN_PROGRESS,
            channels: this.getSalesChannelsForReview(),
          },
          imagesPath:
            this.mcbConfig.package('mc-package-admin').get('mountURL') +
            '/images',
        });

      if (reason) {
        const merchantId: string = this.merchant.$id;
        sendReviewReason(merchantId, reason);
      }
      if (reviewSelection.merchant) {
        await sendMerchantForFinancialReview(this.merchant.$id);
        window.location.reload();
      }
    } catch (e) {
      if (e === USER_DID_NOT_CONFIRM_ERROR) {
        return;
      }

      toast.error('Error while sending data to review');
    }
  }

  sendContractsToReview(reviewedContracts) {
    let contractIds = [];
    Object.keys(reviewedContracts).forEach((key) => {
      if (reviewedContracts[key]) {
        contractIds.push(key);
      }
    });
    return this.store.dispatch(
      // @ts-ignore
      this.contractActions.sendContractsToReview(contractIds)
    );
  }

  async sendSalesChannelForReview(channels): Promise<void> {
    let channelsForReview = Object.keys(channels).reduce((list, key) => {
      if (channels[key]) {
        list.push(key);
      }
      return list;
    }, []);

    if (channelsForReview.length) {
      await sendSalesChannelsForFinancialApproval({
        merchantId: this.merchant.$id,
        salesChannelIds: channelsForReview,
      });
      // @ts-ignore
      await this.store.dispatch(this.merchantFlowActions.setCurrentContracts());
      // @ts-ignore
      await this.store.dispatch(this.merchantFlowActions.setCurrentContract());
    }
  }

  onApprove(): void {
    this.store
      // @ts-ignore
      .dispatch(this.contractActions.onReview())
      .then(() => this.toast.show('Market data successfully approved'))
      .catch(() => this.toast.error('Unable to approve market data'));
  }

  onReject(): void {
    this.store
      // @ts-ignore
      .dispatch(this.contractActions.onReview())
      .then(() => this.toast.show('Market data rejected'))
      .catch(() => this.toast.error('Unable to reject market data'));
  }
  /**
   *  Masterdata is set defined in Merchant requirements after first masterdata export.
   *  Therefore we need to update Merchant requirements data on masterdata export event.
   */
  onMasterdataExport() {
    // @ts-ignore
    this.store.dispatch(this.merchantFlowActions.setFlowStatus());
  }

  async getComplianceDetailsStatus() {
    this.companyComplianceStatus = (
      await fetchMerchantComplianceStatus(this.params.merchantId)
    ).status;
  }

  async getLegalEntityDetails(legalEntityId: string): Promise<void> {
    this.partnerApprovalStatus = (
      await fetchPartnerLegalEntityDetails(legalEntityId)
    ).approvalStatus;
  }

  private listenStore(): void {
    this.unsubscribe = this.store.subscribe(() => {
      const state: FlowState = this.store.getState();
      this.merchant = this.flowSelector.getFlowMerchant(state);
      this.contracts = this.flowSelector.getFlowContracts(state);
      this.merchantStatus = this.flowSelector.getFlowStatus(state);
      this.merchantLoading =
        this.flowSelector.getFlowMerchantLoadingStatus(state);
      this.contractsLoading =
        this.flowSelector.getFlowContractsLoadingStatus(state);
      this.isZPOMerchant = isMerchantZPO(this.merchant);
      this.contract = this.flowSelector.getFlowChannelContracts(
        state,
        this.params.salesChannelId
      )[0];
    });
  }
}
