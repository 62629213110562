<template lang="pug">
     div.grey.lighten-5.pa-5.my-5
        h3.black--text
          | Return carrier
        div(v-if="!preferredCarrier").text-dark.mt-2
          | The partner has not yet selected their carrier preferences on zDirect.
          | Please wait before adding configuration for delivery carriers.
        div(v-if="preferredCarrier").text-dark.mt-2
          | Add configuration for return carrier. For any carriers not listed,
          | contact the OrderX team for overrides or exceptions.
          | Note that you can only configure one return carrier at a time.
        div(
          v-if="showInvisibleReturnCarrierWarning"
          class="d-flex mb-5 pa-3 mcp-admin-merchant-carrier-alert"
        ).mt-4
          v-icon.mr-4.justify-center.text--darken-3 mdi-alert
          div.mt-1
            | The partner is configured with a non-approved carrier. Please delete the incorrect carrier and select the correct approved carrier.
        div(v-if="configuredReturnCarrierSelection && !showAddOrEditReturnCarrier").mt-4
          v-card(
            outlined
            tile
            rounded="sm"
          ).pa-4.rounded.mt-4
            div.d-flex.justify-space-between.align-center
              div
                div(v-if="configuredReturnCarrier")
                  span.text-subtitle-2.font-weight-bold.mr-2.black--text Return carrier:
                  span.text-body-2 {{configuredReturnCarrierSelection.carrier.name}}
                div(v-if="configuredReturnParcelPerformMapping").mt-2
                  span.text-subtitle-2.font-weight-bold.mr-2.black--text Track & trace reference:
                  span.text-body-2 {{configuredReturnCarrierSelection.parcelPerformReference.reference}}
              div
                v-btn.mt-5.mb-6.ml-2.text-none.rounded.font-weight-bold(
                  outlined
                  color="black"
                  @click="onReturnCarrierEditClick"
                ) Edit
        v-btn(v-if="!configuredReturnCarrierSelection && !showAddOrEditReturnCarrier").mt-5.white--text.text-none(
          color="black"
          @click="showAddOrEditReturnCarrier = !showAddOrEditReturnCarrier"
          :loading="isReturnCarrierSubmitting"
        ) Add return carrier configuration
        div(v-if="showAddOrEditReturnCarrier")
          div.mt-2.row
            v-select.ml-4(
              label="Select return carrier"
              :items="carriersList"
              v-model="selectedReturnCarrier"
              @change="onReturnCarrierChange"
              :style="{maxWidth: '40%'}"
            )
            v-select.ml-4(
              label="Select track & trace reference"
              :items="parcelPerformMappingsList"
              v-model="selectedReturnParcelPerformMapping"
              :style="{maxWidth: '40%'}"
              )

          v-btn.mt-5.mb-6.ml-2.text-none.rounded.font-weight-bold(
            outlined
            :class="{'btn-disabled': shouldDisableReturnCarrierSubmit}"
            @click="addNewReturnCarrier"
            color="black"
            style="border-width: 2px"
            :disabled="shouldDisableReturnCarrierSubmit"
            :loading="isReturnCarrierSubmitting"
          ) Save
</template>
<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  name: 'ReturnCarrier',
  props: {
    preferredCarrier: {
      type: Object,
      default: null,
    },
    availableReturnCarrierConfigurations: {
      type: Array,
      default: () => [],
    },
    configuredReturnCarrierSelection: {
      type: Object,
      default: null,
    },
    parcelPerformMappingsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showInvisibleReturnCarrierWarning: false,
      selectedReturnCarrier: null,
      selectedReturnParcelPerformMapping: null,
      showAddOrEditReturnCarrier: false,
      isReturnCarrierSubmitting: false,
    };
  },
  computed: {
    carriersList() {
      return this.availableReturnCarrierConfigurations.map((config) => ({
        value: config.carrier.id,
        text: config.carrier.name,
      }));
    },
    configuredReturnCarrier() {
      return this.configuredReturnCarrierSelection?.carrier;
    },
    configuredReturnParcelPerformMapping() {
      return this.configuredReturnCarrierSelection?.parcelPerformReference;
    },
    shouldDisableReturnCarrierSubmit() {
      return (
        !this.selectedReturnCarrier || !this.selectedReturnParcelPerformMapping
      );
    },
  },
  mounted() {
    this.showInvisibleReturnCarrierWarning =
      !this.availableReturnCarrierConfigurations
        .map((arc) => arc.carrier.id)
        .includes(this.configuredReturnCarrierSelection.carrier.id);
  },
  methods: {
    onReturnCarrierChange(id) {
      this.selectedReturnCarrier = id;
      this.selectedReturnParcelPerformMapping =
        this.availableReturnCarrierConfigurations.find(
          (config) => config.carrier.id === id
        ).carrier.parcelPerformReference;
    },
    addNewReturnCarrier() {
      this.isReturnCarrierSubmitting = true;
      this.$emit('add-return-carrier', {
        carrierId: this.selectedReturnCarrier,
        parcelPerformReferenceId: this.selectedReturnParcelPerformMapping.id,
      });
    },
    onReturnCarrierEditClick() {
      this.showAddOrEditReturnCarrier = true;
      this.selectedReturnCarrier =
        this.configuredReturnCarrierSelection.carrier.id;
      this.selectedReturnParcelPerformMapping =
        this.configuredReturnCarrierSelection.parcelPerformReference;
    },
  },
});
</script>

<style lang="scss" scoped>
.v-application .btn-disabled {
  background-color: transparent !important;
  color: grey !important;
}
</style>
